import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material/styles";

// Create a theme instance.
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4ac7bf',
    },
    secondary: {
      main: '#3eaba3',
    },
    error: {
      main: '#8B0000',
    },
    background: {
      default: '#E0E0E0',
    },
  }
});