import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import logo from "@react-store/common/src/assets/images/logo-biotope.png";
import logoPangea from "../../assets/images/pangea.png";
import { SignOutButton } from "@react-store/common/src/components/ui/SignOutButton";

const NavBar = () => {

    return (
        <>
            <AuthenticatedTemplate>
                <AppBar position="fixed"
                    sx={{
                        zIndex: 10,
                    }}
                >
                    <Toolbar>
                        <Box
                            component="img"
                            sx={{
                                width: "40px",
                                height: "35px",
                                marginRight: "10px",
                            }}
                            src={logoPangea}
                        />
                        <Typography
                            style={{ flexGrow: 1 }}
                            color="inherit"
                            variant="h6"
                        >
                            Gestion de l'espace documentaire
                        </Typography>

                        <SignOutButton />
                        <Box
                            component="img"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                width: "62px",
                                height: "62px",
                            }}
                            src={logo}
                        />
                    </Toolbar>
                </AppBar>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <AppBar position="fixed"
                    sx={{
                        zIndex: 10,
                    }}
                >
                    <Toolbar>
                        <Box
                            component="img"
                            sx={{
                                width: "40px",
                                height: "35px",
                                marginRight: "10px",
                            }}
                            src={logoPangea}
                        />
                        <Typography
                            style={{ flexGrow: 1 }}
                            color="inherit"
                            variant="h6"
                        >
                            Gestion de l'espace documentaire
                        </Typography>
                        <Box
                            component="img"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                width: "62px",
                                height: "62px",
                            }}
                            src={logo}
                        />
                    </Toolbar>
                </AppBar>
            </UnauthenticatedTemplate>
        </>
    );
};

export default NavBar;