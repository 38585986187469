import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../src/styles/theme"//
import {theme as mainTheme } from "@react-store/common/src/styles/theme";

import App from "./App";
import {msalInstanceGed} from "./utils/api/configs/Api"
// MSAL imports
import {
    EventType,
    EventMessage,
    AuthenticationResult,
} from "@azure/msal-browser";
import { createRoot } from "react-dom/client";

msalInstanceGed.initialize().then(() => {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstanceGed.getAllAccounts();
    if (accounts.length > 0) {
        msalInstanceGed.setActiveAccount(accounts[0]);
    }

    msalInstanceGed.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstanceGed.setActiveAccount(account);
        }
    });

    const root = createRoot(
        document.getElementById("root") as HTMLElement
    );
    root.render(
        <Router>
            <ThemeProvider theme={mainTheme}>
                <ThemeProvider theme={theme}>
                    <App pca={msalInstanceGed} />
                </ThemeProvider>
            </ThemeProvider>
        </Router>
    );
});
