import HttpService from "@react-store/common/src/utils/api/configs/axiosConfigs";
import {msalConfig,loginRequest,loginRequestForBackend} from "../../../authConfigGed";
import {
    PublicClientApplication,
} from "@azure/msal-browser";

export const msalInstanceGed = new PublicClientApplication(msalConfig);

export const GedApi = new HttpService(process.env.REACT_APP_GED_API_URL,msalInstanceGed,loginRequest).service;
export const GedApiBack = new HttpService(process.env.REACT_APP_GED_API_URL,msalInstanceGed,loginRequestForBackend).service;
export const SharepointApi = new HttpService(process.env.REACT_APP_GRAPH_SHP_URL,msalInstanceGed,loginRequest).service;