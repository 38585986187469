import { Contract } from "@react-store/common/src/utils/types/ContractTypes";
import { Alert, Box, Button, Card, CardActions, CardContent,List,ListItem,ListItemButton,ListItemIcon,ListItemText,Typography } from "@mui/material";
import ApartmentIcon from '@mui/icons-material/Apartment';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import * as moment from 'moment'
import 'moment/locale/fr';
//import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as React from "react";
//import { StoreSharePointSite, StoreSharePointLists, StoreSharePointAddItem } from "../../../hooks/SharePointStore";
import { GedSharepointListItem } from "@react-store/common/src/utils/types/SharePointTypes";
import { StoreAddContractStatus, StoreContractStatus, StoreRequestProjectWorkspace } from "../../../hooks/ContractStore";
import { useEffect } from "react";
export function ContractDetails({choosenContract}: any) {

const {searchStatus, responseStatus} = StoreContractStatus();
const {addStatus, responseAddedItem} = StoreAddContractStatus();
const {requestProjectWorkspace, responseRequestProjectWorkspace, errorRequestProjectWorkspace} = StoreRequestProjectWorkspace();
// const {searchSite, responseSite, errorSite} = StoreSharePointSite();
// const {searchLists,responseLists,errorList} = StoreSharePointLists();
// const {addItem,errorAdd} = StoreSharePointAddItem();

let choosenContractOne:Contract = choosenContract[0];

useEffect(() => {
  searchStatus(choosenContractOne.id_iovision)
}, [choosenContractOne,responseAddedItem]);

function requestWorkspace(){

  var itemToAdd:GedSharepointListItem={
    Title:choosenContractOne.code_iovision,
    id_iovision:choosenContractOne.id_iovision,
    code_iovision:choosenContractOne.code_iovision,
    nom_projet:choosenContractOne.label,
    type_projet:'',
    chef_projet:choosenContractOne.project_supervisor_email,
    nom_client:choosenContractOne.organism_client_name,
    agence_service:choosenContractOne.agence_service
    
  };
  
  requestProjectWorkspace(choosenContractOne.id_iovision, itemToAdd).then((res) => {
    res
  })
  
  if(responseRequestProjectWorkspace?.id != undefined) {
      addStatus(choosenContractOne.id_iovision).then(()=>{
        //console.log(res);
      })
  }
 
}



const card = (       
<React.Fragment>
    <CardContent>
      <Typography variant="h5" component="div">
        Identifiant projet:  {choosenContractOne.id_iovision}
      </Typography>
      <br />
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
      {choosenContractOne.code_iovision}: {choosenContractOne.label}
      </Typography>
      <br />
      <List>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <ApartmentIcon color="secondary"/>
          </ListItemIcon>
          <ListItemText primary={choosenContractOne.agence_service} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
          <CoPresentIcon color="secondary"/>
          </ListItemIcon>
          <ListItemText primary={choosenContractOne.organism_client_name} />
        </ListItemButton>
      </ListItem>
      </List>           
    </CardContent>
    <CardActions>
      {(responseStatus?.length ==0) &&(<Button 
          variant="contained" 
          size="small" 
          type="submit"
          disabled={responseStatus?.length !=0} 
          onClick={requestWorkspace}
          >
        Création Espace Projet Documentaire
        </Button>
      )}
      {(responseStatus?.length !=0) &&(<Alert severity="info">La demande de création a été effectuée par {responseStatus?.[0]?.created_by} le {moment.locale("fr") && moment(responseStatus?.[0]?.created).format('LLL')}</Alert>)}
    </CardActions>
  </React.Fragment>);

return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card}
      </Card>
      {(false && !errorRequestProjectWorkspace && responseRequestProjectWorkspace?.id != undefined)  &&(<Alert severity="success">L'espace est crée avec succès.</Alert>)}
      {(false && errorRequestProjectWorkspace!=undefined && responseAddedItem!=undefined) &&(<Alert severity="error">Une erreur est survenue.</Alert>)}
    </Box>

  );
}