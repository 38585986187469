import { Contract } from "@react-store/common/src/utils/types/ContractTypes";
import * as React from "react";
import { ContractDetails } from "../components/app/contracts/ContractDetails";
import ContractSearch from "../components/app/contracts/ContractSearch";
export type GraphData = {
displayName: string,
jobTitle: string,
mail: string,
businessPhones: string[],
officeLocation: string
};
export default function Main({graphData} : any) {
console.log(graphData);
//graphData a utiliser pour filtrer la recherche uniquement sur l'agence de l'utilisateur
const [results, setResults] = React.useState<Contract[]>([]);
const [contractDetails, setContractDetails] = React.useState(false);

return (
<div>
    < ContractSearch  results = {results} setResults = {setResults} setContractDetails = {setContractDetails}/>
    <br />
    {contractDetails && results.length!=0 &&(
    <ContractDetails choosenContract = {results} />
    )}
</div>);

}