import { Configuration, PopupRequest } from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        //clientId: process.env.REACT_APP_GED_CLIENT_ID ?? 'default', // ged
        clientId: "fb9de24d-81e1-435a-9e3e-827aa49fe0fe",
        authority: "https://login.microsoftonline.com/a0f7e121-ca5f-4ba9-b64a-82221790d245",
        redirectUri: process.env.REACT_APP_GED_REDIRECT_URI
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

export const loginRequest: PopupRequest = {
   scopes: ['User.Read', 'openid'/*, "Sites.Selected"*/],
   //Pour lister les listes du site sharepoint il faut ajouter le scope  Sites.Selected mais pour le moment ça entraine une erreur
   //invalid_grant: AADSTS65001: The user or administrator has not consented to use the application with ID 'fb9de24d-81e1-435a-9e3e-827aa49fe0fe'

};
export const loginRequestForBackend: PopupRequest = {
    //scopes: [ 'openid', `api://${process.env.REACT_APP_GED_CLIENT_ID ?? 'dummy-default'}/access`],
    scopes: [ 'openid', `api://fb9de24d-81e1-435a-9e3e-827aa49fe0fe/access`],
 };
