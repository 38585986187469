import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
//import methods from '../utils/api/RefService';
import { GedService } from '../utils/api/GedService';
import { ContractStore, Contract, ContractStatusStore, ContractStatus, AddContractStatusStore, RequestProjectWorkspaceStore } from '@react-store/common/src/utils/types/ContractTypes';
import { GedSharepointListItem, WorkspaceResponse } from "@react-store/common/src/utils/types/SharePointTypes";

export const StoreContract = create(persist<ContractStore>(
    (set) => ({
    isLoading: false,
    isFinished: false,
    search: async (ioId:number) => {
        try {
            set({ isLoading: false, isFinished: false});
            const data:Array<Contract> = await GedService.getContract(ioId);
            set(() =>({ isLoading: false, response:data, isFinished: false }));
        }   catch (err: any) {
            const error =
            err?.message || err?.data?.message || 'Unexpected network error.';
            set(() => ({ isLoading: false, response:[], error, isFinished: false }));
        }
    },
    }),
    {
    name: 'ref-store',
    storage: createJSONStorage(() => localStorage),
    },
));

export const StoreContractStatus = create(persist<ContractStatusStore>(
    (set) => ({
        isLoading: false,
        isFinished: false,
        searchStatus: async (ioId:number) => {
            try {
                set({ isLoading: false, isFinished: false});
                const data:Array<ContractStatus> = await GedService.getContractStatus(ioId);
                //console.log(data);
                set(() =>({ isLoading: false, responseStatus:data, isFinished: false }));
            }   catch (err: any) {
                const error =
                err?.message || err?.data?.message || 'Unexpected network error.';
                set(() => ({ isLoading: false, error,isFinished: false }));
            }
        },
    }),
    {
        name: 'ref-store',
        storage: createJSONStorage(() => localStorage),
    },
));

export const StoreAddContractStatus = create(persist<AddContractStatusStore>(
    (set) => ({
        isLoading: false,
        isFinished: false,
        addStatus: async (ioId:number) => {
            try {
                set({ isLoading: false, isFinished: false});
                const data:ContractStatus = await GedService.addContractStatus(ioId);
                set(() =>({ isLoading: false, responseAddedItem:data, isFinished: false }));
            }   catch (err: any) {
                const error =
                err?.message || err?.data?.message || 'Unexpected network error.';
                set(() => ({ isLoading: false, error,isFinished: false }));
            }
        },
    }),
    {
        name: 'ref-store',
        storage: createJSONStorage(() => localStorage),
    },
));

export const StoreRequestProjectWorkspace = create(persist<RequestProjectWorkspaceStore>(
    (set) => ({
        isLoading: false,
        isFinished: false,
        requestProjectWorkspace: async (project_id:number, data:GedSharepointListItem) => {
            try {
                set({ isLoading: false, isFinished: false});
                const response:WorkspaceResponse = await GedService.requestProjectWorkspace(project_id, data);
                set(() =>({ isLoading: false, responseRequestProjectWorkspace:response, isFinished: false }));
            }   catch (err: any) {
                const errorRequestProjectWorkspace = err?.message || err?.data?.message || 'Unexpected network error.';
                set(() => ({ isLoading: false, errorRequestProjectWorkspace, isFinished: false }));
            }
        },
    }),
    {
        name: 'ref-store',
        storage: createJSONStorage(() => localStorage),
    },
));   