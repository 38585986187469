import Box from "@mui/material/Box";
//import logo from "../../../assets/images/logo-biotope.png";
import logo from "../../assets/images/logo-biotope.png"
import { SignInButton } from "../ui/SignInButton";
import Typography from "@mui/material/Typography";

const Aside = () => {
    return (
        <Box
            sx={{
                position: "fixed",
                left: 0,
                top: 0,
                width: "550px",
                height: "100%",
                backgroundColor: "secondary.main",
                zIndex: 2,
            }}
        >
            <SignInButton />
            <Box
                component="img"
                sx={{
                    width: "100px",
                    height: "100px",
                    textAlign: "center",
                    marginTop: "140%",
                    marginLeft: "40%",
                }}
                src={logo}
            />
            <Typography
                color="background.paper"
                variant="subtitle2"
                sx={{
                    textAlign: "center",
                }}
            >
            </Typography>
        </Box>
    );
};

export default Aside;