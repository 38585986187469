import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import LoginIcon from '@mui/icons-material/Login';
import { useMsal } from "@azure/msal-react";

export const SignInButton = (logRequest:any) => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(logRequest);
    }

    return (
        <div>
            <ButtonGroup orientation="vertical">
                <Button
                    onClick={() => handleLogin()}
                    key="loginRedirect"
                    color="primary"
                    variant="contained"
                    startIcon={<LoginIcon />}
                    sx= {{ 
                        margin : 0,
                        position: "absolute",
                        top: "30%",
                        left: "30%",
                    }}
                >
                    Connexion avec Azure AD
                </Button>
            </ButtonGroup>
        </div>
    )
};