import { useEffect, useState } from "react";
// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType/*, InteractionRequiredAuthError, AccountInfo */} from "@azure/msal-browser";
import { loginRequest as loginRequestGed} from "../../../ged/src/authConfigGed";

// Sample app imports
import { ProfileData, GraphData } from "../components/ui/ProfileData";
import { Loading } from "../components/ui/Loading";
import { ErrorComponent } from "../components/ui/ErrorComponent";
import { callMsGraphMe } from "../utils/MsGraphApiCall";
//import { Link as RouterLink } from "react-router-dom";

// Material-ui imports
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";

const ProfileContent = () => {
    const { instance, inProgress ,accounts} = useMsal();
    const [graphData, setGraphData] = useState<null|GraphData>(null);

    useEffect(() => {
        if (!graphData && inProgress === InteractionStatus.None) {
            instance
            .acquireTokenSilent({
                ...loginRequestGed,
                account: accounts[0],
            })
            .then((response) => {
                callMsGraphMe(response.accessToken)
                .then(data => {
                    setGraphData(data);
                });
            });
        }
    }, [inProgress, graphData, instance]);
  
    return (
        <Paper>
            { graphData ? <ProfileData graphData={graphData} /> : null }
        </Paper>
    );
};

export function Profile() {
    const authRequest = {
        ...loginRequestGed
    };

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
            errorComponent={ErrorComponent} 
            loadingComponent={Loading}
        >
            <List>
                <ProfileContent />
            </List>
        </MsalAuthenticationTemplate>
      )
};