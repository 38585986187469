import * as React from 'react';
import TextField from '@mui/material/TextField';
//import { StoreTerrUnit } from '../../hooks/store';
//import { TerrUnitArray } from '../../utils/types';
//import { Contract} from "@react-store/common/src/utils/types/ContractTypes"
//import {ContractDetails} from "./ContractDetails"
import {StoreContract} from '../../../hooks/ContractStore'
import { useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';


export default function ContractSearch({results, setResults ,setContractDetails }:any) {

  const {search, response} = StoreContract();
  const [searchInput, setSearchInput] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const loading = open && searchInput.length >= 1  && results != ([]);
  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      if (active) {
        setResults([]);
        setContractDetails(false);
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);

  function searchItems (_event: React.SyntheticEvent, value: string)  {
    setSearchInput(value);
    if (value !== ''  && value.length >= 1) {
      search(Number(value));
    }
  }

  return (
    <div>
    <Autocomplete
      id="contract-search"
      sx={{ width: 600, margin: 'auto' }}
      options={response ?? []}
      getOptionLabel={(option) => String(option.id_iovision)}
      isOptionEqualToValue={(option, name) => option.id_iovision === name.id_iovision}
      onChange={()=>{
        if(response){
          setResults(response);
          setContractDetails(true);
        }

      }}
      onInputChange={searchItems}
      loading={loading}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id_iovision}>
            {option.id_iovision + " - "+option.label}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Chercher un contrat"
          InputProps={{
            ...params.InputProps,
            startAdornment: ( <SearchIcon /> 
            ),
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
    </div>
  );
}