
import axios from "axios"
import { callMsGraph } from "../../MsGraphApiCall";


class HttpService {
service: any;
constructor(apiUrl: any, msalInstance: any, logRequest: any) {
  this.service = axios.create({
    baseURL: apiUrl,
  })

  this.service.interceptors.request.use(async (req: { headers: { Authorization: string } }) =>{
    let bearer = await callMsGraph(msalInstance, logRequest);
    req.headers.Authorization =  bearer;
    return req;
  });


  this.service.interceptors.response.use(undefined, this.handleError);
}

handleError(error: { response: { status: any } }) {
  const statusCode = error.response?.status

  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}
}

export default HttpService;
