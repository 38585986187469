import Button from "@mui/material/Button";
import { useMsal } from "@azure/msal-react";
import LogoutIcon from '@mui/icons-material/Logout';

export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect();
    }

    return (
        <div>
            <Button
                onClick={() => handleLogout()}
                key="logoutPopup"
                variant="outlined"
                startIcon={<LogoutIcon />}
                sx = {{
                    color:"background.paper",
                    borderColor:"background.paper",
                    marginRight : 5,
                }}
            >
                Déconnexion
            </Button>
        </div>
    )
};