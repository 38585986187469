import { graphConfig} from "../graphConfig";
import { jwtDecode } from "jwt-decode";

export async function callMsGraph(msalInstance:any ,logRequest:any) {
    const account = msalInstance.getActiveAccount() ;
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }


    const response = await msalInstance.acquireTokenSilent({
        ...logRequest ,
        account: account
    });
    const bearer =`Bearer ${response.accessToken}`;
    return bearer;
}

export async function callMsGraphMe(accessToken:String) {

    const bearer = `Bearer ${accessToken}`;

    const headers = new Headers();
    

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint,options)
        .then(response => response.json())
        .catch(error => console.log(error));
}
export async function callMsGraphById(accessToken:String) {

    const bearer = `Bearer ${accessToken}`;
    var decoded = JSON.stringify(jwtDecode(String(accessToken)));
    const userId = JSON.parse(decoded)["oid"];

    const headers = new Headers();
    

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(graphConfig.graphByUser+userId+"?$select=department",options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

